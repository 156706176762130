import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiMenu2Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import Logo from '../../Assets/pandit_shivkumar_logo.png';
import Sidebar from './Sidebar';
import LoginPopUp from './LoginPopUp';

const Navbar = () => {
  const [menu, setMenu] = useState("home");
  const [showSidebar, setShowSidebar] = useState(false);
  const [showLoginPopUp, setShowLoginPopUp] = useState(false);

  return (
    <>
      {showLoginPopUp && <LoginPopUp setShowLoginPopUp={setShowLoginPopUp} />}
      {showSidebar && (
        <Sidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
      )}
      <div className="py-5 flex justify-between items-center">
        <div className="flex gap-4">
          <div className="block md:hidden cursor-pointer">
            {showSidebar ? (
              <RxCross2 size={25} onClick={() => setShowSidebar(!showSidebar)} />
            ) : (
              <RiMenu2Line size={25} onClick={() => setShowSidebar(!showSidebar)} />
            )}
          </div>
          <img src={Logo} alt="logo" className="ms-3 w-34 hidden sm:block" />
        </div>
        <ul className="hidden md:flex list-none gap-5 text-[#01004C] text-lg">
          <Link to="/" className={menu === "home" ? "border-b-2 border-[#01004C] pb-1" : ""} onClick={() => setMenu("home")}>Home</Link>
          <Link to="/blogs" className={menu === "blogs" ? "border-b-2 border-[#01004C] pb-1" : ""} onClick={() => setMenu("blogs")}>Blogs</Link>
          <Link to="/aboutus" className={menu === "About-Us" ? "border-b-2 border-[#01004C] pb-1" : ""} onClick={() => setMenu("About-Us")}>About-Us</Link>
          <Link to="/contactus" className={menu === "contact-us" ? "border-b-2 border-[#01004C] pb-1" : ""} onClick={() => setMenu("contact-us")}>Contact us</Link>
        </ul>
        <div className="flex items-center gap-10">
          <div className="relative cursor-pointer">
            <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-tomato rounded-full"></div>
          </div>
          <button onClick={() => setShowLoginPopUp(true)} className="bg-transparent text-[#01004C] text-lg border border-tomato px-8 py-2 rounded-full border-[#01004C] hover:bg-[#01004C] hover:text-white transition duration-300">Sign In</button>
        </div>
      </div>
    </>
  );
}

export default Navbar;
