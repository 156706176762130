import React from 'react'
import navbarlogo from "../../Assets/UserImg.png"

const Navbar = () => {
  return (
    <>


<nav class="bg-gray-800">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      
      <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex flex-shrink-0 items-center">
          <img class="h-8 w-auto" src={navbarlogo} alt="Your Company"/>
        </div>
      
      </div>
      
    </div>
  </div>


</nav>







    </>
  )
}

export default Navbar