import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';



const ContactUs = () => {
    return (
        <>
            <Navbar />
            <div className=' bg-gray-100'>
                <h4 className='text-4xl font-bold p-5 text-center'>Contact Us</h4>
                <hr className='w-full  bg-gray-300 mb-10' />


                <div className="flex flex-wrap md:justify-around md:ml-0 ml-20 justify-start   gap-10">
                    {/*  */}
                    <div className="flex flex-col mb-10">
                        <h5 className="text-lg font-semibold mb-2">Investor Queries and Grievances</h5>
                        <h6 className="text-base font-semibold">Laksh Gundale</h6>
                        <p className="text-gray-600">Vice President, Investor Relations</p>
                        <p className="text-gray-600">Email: <span className="text-tomato">ir@tomato.in</span></p>
                        <br />
                        <h6 className="text-base font-semibold">Spandan Jagtap</h6>
                        <p className="text-gray-600">Company Secretary</p>
                        <p className="text-gray-600">Email: <span className="text-tomato">secretarial@tomato.in</span></p>
                    </div>
                    {/*  */}
                    <div className="flex flex-col mb-10 ">
                        <h5 className="text-lg font-semibold mb-2">Public Relations and Media</h5>
                        <h6 className="text-base font-semibold">Mahesh Rohane</h6>
                        <p className="text-gray-600">Vice President, Corporate Affairs</p>
                        <br /><br />
                        <h6 className="text-base font-semibold">Customer Support</h6>
                        <p className="text-gray-600">Email: <span className='text-tomato'>support@tomato.in</span></p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;
