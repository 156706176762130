import React from "react";
import logo from "../../Assets/pandit_shivkumar_logo.png";

const PageNotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <img src={logo} alt="" className="p-4" />
            <h1 className="text-7xl font-bold text-blue-950 mb-4">404 Error</h1>
            <h1 className="text-2xl font-semibold text-gray-800">Sorry! Page Not Found</h1>
        </div>
    );
};

export default PageNotFound;
