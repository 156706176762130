import React, { useState } from "react";
// import Img2 from "./img/2.webp";
import Navbar from "./Navbar";
import Footer from "./Footer";

export const LandingPage = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
    <Navbar/>
      {/* main section */}
      <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row items-center">
        {/* left side */}
        <div className="md:w-1/2 mb-8 md:mb-0 md:mr-4 text-center">
          {/* title */}
          <p className="text-2xl md:text-4xl font-bold mb-4">
          Celebrating Success: Our Transformative Vastu Event
          </p>
          {/* text */}
          <p className="text-lg md:text-xl">
          Join us in celebrating the resounding success of our recent Vastu event, 
            {isExpanded && (
              <span>
                {" "}
                where ancient wisdom and modern living harmoniously converged to inspire, educate, and transform lives.
              </span>
            )}
            <button onClick={toggleExpand} className="text-blue-500 ml-2">
              {isExpanded ? "View Less" : "View More"}
            </button>
          </p>
          {/* button */}
          <button className="my-3 rounded-3xl py-2 px-4 border-2 border-[#01004C] hover:bg-[#01004C] hover:text-white duration-500  hover:text-3xl  md:text-2xl text-[#01004C]">
            Get Started
          </button>
        </div>
        {/* right side */}
        <div className="md:w-1/2">
          <img
            // src={Img2}
            src='https://www.suyogah.com/cdn/shop/articles/blog_banner_incense_2.jpg?v=1718951269'
            alt="Banner"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default LandingPage;
