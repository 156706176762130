import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import { Link } from "react-router-dom";
import config from "../BackendConfig/Config"

const ViewClient = () => {
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsPerPage] = useState(5);

  // Assume userID is fetched from localStorage
  const userId = localStorage.getItem("userId");

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${config.backendapi}/api/cases/`, {
        params: { userId },
      });
      if (response.data && Array.isArray(response.data.data)) {
        // Filter the clients based on the logged-in user's ID
        const filteredClients = response.data.data.filter(
          (client) => client.executiveID === userId
        );
  
        // Create a Set to store unique client names
        const clientNames = new Set();
        const uniqueClients = filteredClients.filter(client => {
          const isDuplicate = clientNames.has(client.client_id);
          clientNames.add(client.client_id);
          return !isDuplicate;
        });
  
        setClients(uniqueClients);
      } else {
        console.error("API response is not in expected format", response.data);
      }
    } catch (error) {
      console.error("Error fetching clients", error);
    }
  };
  

  useEffect(() => {
    fetchClients();
  }, []);

  // Get current clients
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = clients.slice(indexOfFirstClient, indexOfLastClient);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Sidebar />

      <div className="min-h-screen w-full flex justify-center bg-gray-100 p-4">
        <div className="w-full max-w-xl mt-14 ml-0 md:ml-20">
          <div className="flex justify-center items-center">
            <h1 className="text-3xl font-bold text-center p-4">My Clients</h1>
          </div>
          {currentClients.map((client) => (
            <div
              key={client._id}
              className="flex flex-col md:flex-row justify-between p-5 border-b border-blue-950"
            >
              <div className="flex flex-col mb-4 md:mb-0">
                <label className="text-xl font-bold text-gray-800">
                  {client.client}
                </label>

              </div>
              <div className="mt-4 md:mt-0">
                <Link
                  to={`/individualclients/${client.client_id}`}
                 className="w-full md:w-auto bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                >
                  View Details
                </Link>
              </div>
            </div>
          ))}
          <div className="flex justify-center mt-4 p-4">
            {clients.length > clientsPerPage && (
              <ul className="flex list-none">
                {Array(Math.ceil(clients.length / clientsPerPage))
                  .fill()
                  .map((_, index) => (
                    <li key={index}>
                      <button
                        className={`mx-1 px-3 py-1 border border-gray-300 rounded ${
                          currentPage === index + 1
                            ? "bg-blue-950 text-white font-bold"
                            : ""
                        }`}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewClient;
