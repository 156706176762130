// Import necessary components and libraries
import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import AddFeedback from "./components/Clients/AddFeedback";
import ExecutiveProfile from './components/Executive/ExecutiveProfile';
import Navbar from "./components/Navbar/Navbar";
import ViewClients from './components/Clients/ViewClients';
import IndividualClients from './components/Clients/IndividualClients';
import AddFallowup from './components/Cases/AddFallowup';
import Sidebar from './components/Sidebar/Sidebar';
import ProductDetails from './components/Cases/ProductDetails';
import ClientReport from './components/Report/ClientReport';
import IndividualCases from './components/Cases/IndividualCases';
import ViewCases from './components/Cases/ViewCases';
import UpcomingFollowup from './components/Cases/UpcomingFollowup';
import UpdatePass from './components/UpdatePass/UpdatePass';
import PageNotFound from './components/PageNotFound/PageNotFound';
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute"
import PublicRoute from './components/ProtectedRoute/PublicRoute';



// rouitng for the Landing page 

import LandingPageNavbar from "./components/LandingPage/Navbar"
import LandingPageSidebar from "./components/LandingPage/Sidebar"
import LandingPage from './components/LandingPage/LandingPage';
import LandingFooter from "./components/LandingPage/Footer";
import LandingBlog from "./components/LandingPage/Blog";
import LandingAbout from "./components/LandingPage/About";
import LandingContact from "./components/LandingPage/Contact";
import ScrolltoTop from './components/LandingPage/ScrolltoTop';


function App() {
  const [showSidebar, setShowSidebar] = useState(false);


  return (
    <Router>
      <Routes>
      
        {/* Other routes */}
      
        <Route path='/' element={<PublicRoute element={<LandingPage />} restrictedPath="/executiveprofile" />} />
        <Route path="/updatepass" element={<ProtectedRoute element={<UpdatePass />} />} />
        <Route path="/individualclients/:id" element={<ProtectedRoute element={<IndividualClients />} />} />
        <Route path="/addfeedback" element={<ProtectedRoute element={<AddFeedback />} />} />
        <Route path="/executiveprofile" element={<ProtectedRoute element={<ExecutiveProfile />} />} />
        <Route path="/navbar" element={<Navbar />} />
        <Route path="/sidebar" element={<Sidebar />} />
        <Route path="/viewclients" element={<ProtectedRoute element={<ViewClients />} />} />
        <Route path="/addfallowup" element={<ProtectedRoute element={<AddFallowup />} />} />
        <Route path="/productdetails" element={<ProtectedRoute element={<ProductDetails />} />} />
        <Route path="/clientreport" element={<ProtectedRoute element={<ClientReport />} />} />
        <Route path="/individualcases/:id" element={<ProtectedRoute element={<IndividualCases />} />} />
        <Route path="/viewcases" element={<ProtectedRoute element={<ViewCases />} />} />
        <Route path="/upcomingFollowup" element={<ProtectedRoute element={<UpcomingFollowup />} />} />
        <Route path="/*" element={<PageNotFound />} />
        
        {/* routing for the landing page / */}
        <Route path='/lnavbar' element={<LandingPageNavbar
          setShowSidebar={setShowSidebar}
          showSidebar={showSidebar}
        />} />
        <Route path='/lsidebar' element={<LandingPageSidebar />} />
        <Route path='/footer' element={<LandingFooter />} />
        <Route path='/blogs' element={<LandingBlog />} />
        <Route path='/aboutus' element={<LandingAbout />} />
        <Route path='/contactus' element={<LandingContact />} />
       
      </Routes>
    </Router>
  );
}

export default App;
