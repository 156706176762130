import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Add this import
import userLogo from "../../Assets/UserImg.png";
import Sidebar from "../Sidebar/Sidebar";
import "./ExecutiveProfile.css";
import config from "../BackendConfig/Config"


const ExecutiveProfile = () => {
  // State variables to store user data, editing status, and message
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isEditing, setIsEditing] = useState(false); // New state variable
  const [message, setMessage] = useState(""); // New state variable for message
  const [initialData, setInitialData] = useState({}); // Store initial data
  const [showUpdatePassword, setShowUpdatePassword] = useState(false); // New state variable for showing Update Password link

  const [errors, setErrors] = useState({});

  // Function to validate form fields
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // Validate first name
    if (!firstName.trim()) {
      errors.firstName = "First name is required";
      isValid = false;
    } else if (!/^[a-zA-Z]+$/.test(firstName)) {
      errors.firstName = "First name should contain only letters";
      isValid = false;
    } else if (/\d/.test(firstName)) {
      errors.firstName = "First name should not contain numbers";
      isValid = false;
    }

    // Validate last name
    if (!lastName.trim()) {
      errors.lastName = "Last name is required";
      isValid = false;
    } else if (!/^[a-zA-Z]+$/.test(lastName)) {
      errors.lastName = "Last name should contain only letters";
      isValid = false;
    } else if (/\d/.test(lastName)) {
      errors.lastName = "Last name should not contain numbers";
      isValid = false;
    }

    // Validate email
    if (!email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email address";
      isValid = false;
    }

    // Validate phone number
    if (!phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      errors.phoneNumber = "Phone number should contain exactly 10 numbers";
      isValid = false;
    } else if (/\D/.test(phoneNumber)) {
      errors.phoneNumber = "Phone number should not contain characters";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };
  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      const response = await fetch(
        `${config.backendapi}/api/executives/${userId}`
      );

      if (response.ok) {
        const userData = await response.json();
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setEmail(userData.email);
        setPhoneNumber(userData.phoneNumber);
        setInitialData(userData); // Store initial data
      } else if (response.status === 404) {
        console.error("Executive profile not found");
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };




  // Function to handle saving user data
  const saveUserData = async () => {
    const isValid = validateForm();

    if (!isValid) {
      setMessage("Please fix the errors before saving.");
      return;
    }

    setMessage("");

    // Check if any field has been modified
    const userId = localStorage.getItem("userId");

    if (
      firstName === initialData.firstName &&
      lastName === initialData.lastName &&
      email === initialData.email &&
      phoneNumber === initialData.phoneNumber
    ) {
      setMessage("No changes made to the profile");
      return;
    }

    try {
      const response = await fetch(
        `${config.backendapi}/api/executives/${userId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber,
          }),
        }
      );
      if (response.ok) {
        setIsEditing(false); // Disable editing after successful save
        setMessage("Profile updated successfully"); // Set success message
        setTimeout(() => {
          setMessage(""); // Clear message after 3 seconds
        }, 3000);
      } else if (response.status === 404) {
        setMessage("Executive profile not found"); // Set error message for not found
      } else {
        console.error("Failed to update user data");
        setMessage("Failed to update profile"); // Set failure message
      }
    } catch (error) {
      console.error("Error updating user data:", error);
      setMessage("Error updating profile"); // Set error message
    }
  };



  // Function to handle cancelling edit
  const cancelEdit = () => {
    // Reset fields to initial values
    setFirstName(initialData.firstName);
    setLastName(initialData.lastName);
    setEmail(initialData.email);
    setPhoneNumber(initialData.phoneNumber);
    setIsEditing(false); // Disable editing mode
    setMessage(""); // Clear any existing message
  };

  // Fetch user data on component mount
  useEffect(() => {
    fetchUserData();
  }, []); // Empty dependency array to fetch data only once on component mount

  useEffect(() => {
    setShowUpdatePassword(isEditing);
  }, [isEditing]);

  return (

    <>
      <Sidebar />
      <div className="executiveprofilecont min-h-screen w-full flex items-center justify-center bg-gray-100">
        <div className="w-full max-w-xl">
          <div className="executiveprofilecontborder max-w-md border-2 border-blue-950 bg-white shadow-md rounded-lg overflow-hidden mt-10">
            <div className="p-4">
              <div className="text-center mb-4">
                <img
                  src={userLogo}
                  alt="User"
                  className="w-24 h-24 rounded-full mx-auto"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${isEditing ? "" : "bg-gray-200"
                    }`}
                  readOnly={!isEditing}
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs italic">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${isEditing ? "" : "bg-gray-200"
                    }`}
                  readOnly={!isEditing}
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs italic">
                    {errors.lastName}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email
                </label>
                <input
                  type="text"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${isEditing ? "" : "bg-gray-200"
                    }`}
                  readOnly={!isEditing}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs italic">{errors.email}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Phone
                </label>
                <input
                  type="tel"
                  placeholder="Enter your Phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${isEditing ? "" : "bg-gray-200"
                    }`}
                  readOnly={!isEditing}
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-xs italic">
                    {errors.phoneNumber}
                  </p>
                )}
              </div>
              <div>
                {showUpdatePassword && (
                  <div className="SignUp mt-4 mb-4">
                    <p className="DontAcc">
                      <span className="Login underline">
                        <Link to="/updatepass" className="underline">Update Password</Link>
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="flex justify-center">
                {!isEditing ? (
                  <button
                    className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-4"
                    onClick={() => setIsEditing(true)}
                  >
                    Edit Profile
                  </button>
                ) : (
                  <>
                    <button
                      className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-4"
                      onClick={saveUserData}
                    >
                      Save Profile
                    </button>
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" onClick={cancelEdit}>
                      Cancel
                    </button>
                  </>
                )}
              </div>
              <div className="text-center mt-2 text-gray-700">
                {message && <p>{message}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExecutiveProfile;
