import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import "./CasesMR.css"
import config from "../BackendConfig/Config"


const IndividualCases = () => {
  const { id } = useParams();
  const navigate = useNavigate(); // Use the useNavigate hook
  const [cases, setCases] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [editedCase, setEditedCase] = useState({
    contactNumber: '',
    firstMeetingDate: '', // Changed from firstMeetingDate to meetingDate
    // image: '',
    issues: '',
    solution: '',
    payment: ''
  });

  useEffect(() => {
    axios.get(`${config.backendapi}/api/cases/${id}`)
      .then(response => {
        console.log("hello ",response.data);
        setCases(response.data);
        setEditedCase(response.data);
      })
      .catch(error => {
        console.error(`Error fetching client details: ${error}`);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Validation for meeting date
    // if (name === 'firstMeetingDate' && !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
    //   setErrorMessage('Invalid date format (YYYY-MM-DD)');
    //   setTimeout(() => setErrorMessage(''), 3000);
    //   return;
    // }
    setEditedCase(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // Validate contactNumber
    if (!editedCase.contactNumber.trim()) {
      errors.contactNumber = "Contact Number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(editedCase.contactNumber)) {
      errors.contactNumber = "Contact Number should contain exactly 10 numbers";
      isValid = false;
    } else if (/\D/.test(editedCase.contactNumber)) {
      errors.contactNumber = "Phone number should not contain characters";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  }

  const handleSaveChanges = () => {
    const isValid = validateForm();

    if (!isValid) {
      setErrorMessage("Please fix the errors before saving.");
      setSuccessMessage('');
      return;
    }

    setErrorMessage("");
    const changesMade = Object.keys(editedCase).some(key => editedCase[key] !== cases[key]);

    if (!changesMade) {
      setErrorMessage('No changes made');
      setTimeout(() => setErrorMessage(''), 3000);
      setSuccessMessage('');
      return;
    }

    axios.patch(`${config.backendapi}/api/cases/${id}`, editedCase)
      .then(response => {
        setSuccessMessage('Case updated successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
        setEditMode(true);
      })
      .catch(error => {
        console.error('Error updating data:', error);
        setErrorMessage('Failed to update data');
        setTimeout(() => setErrorMessage(''), 3000);
      });
  };

  const handleCancelChanges = () => {
    setEditMode(false);
    setErrorMessage('');
    setSuccessMessage('');
    setEditedCase({
      contactNumber: cases.contactNumber,
      meetingDate: cases.firstMeetingDate, 
      image: cases.image,
      issues: cases.issues,
      solution: cases.solution,
      payment: cases.payment
    });
  };
  // Function to handle back button click
  const handleBackClick = () => {
    navigate('/viewcases');
  };

  if (!cases) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <Sidebar />
      </div>
      <div className="viewcases flex flex-col justify-center items-center min-h-screen w-full bg-gray-100">
        <div className="w-full sm:w-1/2 md:w-1/3 border-2 border-blue-950 bg-white rounded-lg mt-16">
          <div className="rounded px-8 pt-6 pb-8 mb-4">
            <h1 className="text-2xl font-bold text-center mb-4">{cases.caseLabel} Case</h1>
            {/* Render individual case data here */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="clientContactNumber">
                Client Contact Number:
              </label>
              <input
                id="contactNumber"
                name='contactNumber'
                type="text"
                value={editedCase.contactNumber}
                onChange={handleInputChange}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? "" : "bg-gray-200"}`}
                readOnly={!editMode}
              />
              {errors.contactNumber && (
                <p className="text-red-500 text-xs italic">{errors.contactNumber}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstMeetingDate">
                First Meeting Date:
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
                id="firstMeetingDate"
                name='firstMeetingDate'
                type="date"
                value={editedCase.firstMeetingDate}
                onChange={handleInputChange}
                readOnly={!editMode}
              />
            </div>
    
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="issues">
                Issues:
              </label>
              <textarea
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
                id="issues"
                name='issues'
                rows="4"
                value={editedCase.issues}
                onChange={handleInputChange}
                readOnly={!editMode}
              />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="solution">
              Solution:
            </label>
            <textarea
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
              id="solution"
              name='solution'
              rows="4"
              value={editedCase.solution}
              onChange={handleInputChange}
              readOnly={!editMode}
            />
          </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="payment">
                Payment (in Rupees):
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
                id="payment"
                name='payment'
                type="number"
                value={editedCase.payment}
                onChange={handleInputChange}
                readOnly={!editMode}
              />
            </div>
            <div className="flex justify-center">
              {editMode ? (
                <div className="flex justify-center">
                  <button
                    className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4 mr-2"
                    type="button"
                    onClick={handleSaveChanges}
                  >
                    Save
                  </button>
                  <button
                    className="bg-red-600 hover:bg-red-400 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4"
                    type="button"
                    onClick={handleCancelChanges}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="flex justify-center gap-10">
                  <button
                    className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4"
                    type="button"
                    onClick={toggleEditMode}
                  >
                    Edit Case
                  </button>
                  <button
                  className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4"
                  type="button"
                  onClick={handleBackClick}
                >
                  Back
                </button>
                </div>
              )}
            </div>
            <div>
              {successMessage && <p className="text-gray-700 text-center">{successMessage}</p>}
              {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualCases;
