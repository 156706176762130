import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import "./CasesMR.css"
import config from "../BackendConfig/Config"


const AddFollowup = () => {
  const [cases, setCases] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedCase, setSelectedCase] = useState('');
  const [formData, setFormData] = useState({
    exeID: localStorage.getItem("userId") || "",
    title: '',
    clientID: '',
    clientName: '',
    caseID: '',
    visitDate: '',
    visitTime: '',
    visitMode: '',
    details: ''
  });
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.backendapi}/api/followup`,
        formData
      );
      setMessage('Follow-up added successfully');
      setFormData({
        exeID: localStorage.getItem("userId") || "",
        title: '',
        clientID: '',
        clientName: '',
        caseID: '',
        visitDate: '',
        visitTime: '',
        visitMode: '',
        details: ''
      });
    } catch (error) {
      if (error.response) {
        setMessage(`Failed to add follow-up: ${error.response.data.message}`);
      } else {
        setMessage('Failed to add follow-up: Network error');
      }
    }
  };

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const response = await axios.get(`${config.backendapi}/api/cases`);
        const casesData = response.data.data;
        const userCases = casesData.filter((caseItem) => caseItem.executiveID === userId);
        setCases(userCases);
        const uniqueClients = Array.from(
          new Set(userCases.map((caseItem) => JSON.stringify({
            client_id: caseItem.client_id,
            client: caseItem.client
          })))
        ).map(client => JSON.parse(client));
        setClients(uniqueClients);
      } catch (error) {
        console.error('Error fetching cases:', error);
      }
    };
    fetchCases();
  }, []);

  const handleSelectChange = (event) => {
    const clientId = event.target.value;
    const selectedClientData = clients.find(client => client.client_id === clientId);
    const clientName = selectedClientData ? selectedClientData.client : '';

    setSelectedClient(clientId);
    setSelectedCase("");
    setFormData({ ...formData, clientID: clientId, clientName: clientName, caseID: "" });
  };

  const handleCaseSelectChange = (event) => {
    const caseId = event.target.value;
    setSelectedCase(caseId);
    setFormData({ ...formData, caseID: caseId });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <div>
      <div>
        <Sidebar />
      </div>
      <div className="viewcases flex flex-col justify-center items-center min-h-screen w-full bg-gray-100 ">
        <div className="w-full sm:w-1/2 md:w-1/3 border-2 border-blue-950 bg-white  rounded-lg mt-20">
          <div className="rounded px-8 pt-6 pb-8 mb-4">
            <h1 className="text-2xl font-bold text-center mb-4">Add Follow-Up</h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                  Title
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="title"
                  type="text"
                  placeholder="Enter Title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="client" className="block text-gray-700 text-sm font-bold mb-2">
                  Choose Client
                </label>
                <select
                  id="client"
                  name="client"
                  className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={selectedClient}
                  onChange={handleSelectChange}
                >
                  <option value="">Select Client</option>
                  {clients.map((client) => (
                    <option key={client.client_id} value={client.client_id}>
                      {client.client}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="caseID" className="block text-gray-700 text-sm font-bold mb-2">
                  Choose Case
                </label>
                <select
                  id="caseID"
                  name="caseID"
                  className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  onChange={handleCaseSelectChange}
                  value={selectedCase}
                  disabled={!selectedClient}
                >
                  <option value="">Select Case</option>
                  {cases
                    .filter((ca) => ca.client_id === selectedClient)
                    .map((ca) => (
                      <option key={ca._id} value={ca._id}>
                        {ca.caseLabel}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="visitDate">
                  Visit Date
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="visitDate"
                  type="date"
                  placeholder="Visit Date"
                  name="visitDate"
                  value={formData.visitDate}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="visitTime">
                  Visit Time
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="visitTime"
                  type="time"
                  placeholder="Visit Time"
                  name="visitTime"
                  value={formData.visitTime}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="visitMode">
                  Visit Mode
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="visitMode"
                  type="text"
                  placeholder="Visit Mode"
                  name="visitMode"
                  value={formData.visitMode}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="details">
                  Details
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="details"
                  placeholder="Details"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                />
              </div>
              <div className="flex justify-center">
                <button
                  className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4"
                  type="submit"
                >
                  Add
                </button>
              </div>
              <div>
                {message && (
                  <div className={`text-center ${message.includes('success') ? 'text-green-600' : 'text-red-600'}`}>
                    {message}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFollowup;
