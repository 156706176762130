import React, { useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { FaFileDownload } from "react-icons/fa";
import axios from 'axios';
import Sidebar from "../Sidebar/Sidebar";
import "./CasesMR.css"
import config from "../BackendConfig/Config"


const UpcomingFollowup = () => {
    const [formData, setFormData] = useState([]);
    const [selectedClientName, setSelectedClientName] = useState('');
    const [selectedVisitMode, setSelectedVisitMode] = useState('');
    const [filter, setFilter] = useState('');
    const [sortBy, setSortBy] = useState('asc'); // Default sorting order
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(8);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userId = localStorage.getItem('userId');
                const response = await axios.get(`${config.backendapi}/api/followup/executive/${userId}`);
                setFormData(response.data);
            } catch (error) {
                console.error(`Error fetching data: ${error}`);
            }
        };

        fetchData();
    }, []);

    const handleClientNameSelect = (event) => {
        setSelectedClientName(event.target.value);
    };

    const handleVisitModeSelect = (event) => {
        setSelectedVisitMode(event.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const toggleSortOrder = () => {
        setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
    };

    // Filtered and sorted data based on selected options and filter input
    const filteredData = formData.filter(item => {
        const clientNameMatch = selectedClientName ? item.clientName === selectedClientName : true;
        const visitModeMatch = selectedVisitMode ? item.visitMode === selectedVisitMode : true;
        const filterMatch = (item.clientName && item.clientName.toLowerCase().includes(filter.toLowerCase())) ||
            (item.visitMode && item.visitMode.toLowerCase().includes(filter.toLowerCase())) ||
            (item.title && item.title.toLowerCase().includes(filter.toLowerCase()));
        return clientNameMatch && visitModeMatch && filterMatch;
    }).sort((a, b) => {
        if (!a.clientName || !b.clientName) return 0; // Handle undefined clientName
        const sortOrder = sortBy === 'asc' ? 1 : -1;
        // Sort by clientName alphabetically
        return a.clientName.localeCompare(b.clientName) * sortOrder;
    });

    // Pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div>
            <div>
                <Sidebar />
            </div>
            <div className="viewcases flex flex-col justify-center items-center min-h-screen w-full bg-gray-100 ">

                <div className="w-full sm:w-1/2 md:w-1/3 rounded-lg mt-10">
                    <div className="rounded px-8 pt-6 pb-8 mb-4">
                        <h1 className="text-2xl font-bold text-center">Upcoming Follow Ups</h1>
                        <div class="flex flex-col md:flex-row items-center gap-1 mt-7">

                            <select
                                value={selectedClientName}
                                onChange={handleClientNameSelect}
                                class="border border-black rounded-md py-2 px-4 mb-2 md:mb-0 md:mr-2 md:w-auto"
                            >
                                <option value="">Client Name</option>
                                {formData.map((followup, index) => (
                                    <option key={index} value={followup.clientName}>{followup.clientName}</option>
                                ))}
                            </select>

                            <select
                                value={selectedVisitMode}
                                onChange={handleVisitModeSelect}
                                class="border border-black rounded-md py-2 px-4 mb-2 md:mb-0 md:mr-2 md:w-auto"
                            >
                                <option value="">Mode</option>
                                {formData.map((followup, index) => (
                                    <option key={index} value={followup.visitMode}>{followup.visitMode}</option>
                                ))}
                            </select>

                            <input
                                type="text"
                                placeholder="Filter..."
                                value={filter}
                                onChange={handleFilterChange}
                                class="border border-black rounded-md py-2 px-4 w-full md:w-28"
                            />
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="table-fixed border border-blue-950">
                            <thead>
                                <tr>
                                    <th className="border-2 border-blue-950 px-2 py-2 w-1/6 text-sm">Title/Aim</th>
                                    <th className="border-2 border-blue-950 px-2 py-2 w-1/6 text-sm">Date</th>
                                    <th className="border-2 border-blue-950 px-2 py-2 w-1/6 text-sm">Time</th>
                                    <th className="border-2 border-blue-950 px-2 py-2 w-1/6 text-sm">Mode</th>
                                    <th className="border-2 border-blue-950 px-2 py-2 w-1/6 text-sm">Client Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((followup, index) => (
                                    <tr key={index}>
                                        <td className="border border-blue-950 px-2 py-2 w-1/6 text-sm">{followup.title}</td>
                                        <td className="border border-blue-950 px-2 py-2 w-1/6 text-sm">{followup.visitDate}</td>
                                        <td className="border border-blue-950 px-2 py-2 w-1/6 text-sm">{followup.visitTime}</td>
                                        <td className="border border-blue-950 px-2 py-2 w-1/6 text-sm">{followup.visitMode}</td>
                                        <td className="border border-blue-950 px-2 py-2 w-1/6 text-sm">{followup.clientName}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="p-4">
                            <ul className="flex justify-center">
                                {Array.from({ length: Math.ceil(filteredData.length / recordsPerPage) }, (_, index) => (
                                    <button
                                        key={index}
                                        className={`mx-1 px-3 py-1 border border-gray-300 rounded ${currentPage === index + 1 ? 'bg-blue-950 text-white font-bold' : ''
                                            }`}
                                        onClick={() => paginate(index + 1)}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpcomingFollowup;
