import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaCheck, FaFileDownload } from "react-icons/fa";
import "./ClientReport.css";
import Sidebar from "./../Sidebar/Sidebar";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import config from "../BackendConfig/Config"


function ClientReport() {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [executiveData, setExecutiveData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 4; // Define clientsPerPage here

  useEffect(() => {
    // Fetch executive's ID from local storage
    const userId = localStorage.getItem("userId");

    // Make a request to fetch clients
    axios
      .get(`${config.backendapi}/api/reports`)
      .then((response) => {
        // Filter clients based on executive's ID
        const executiveClients = response.data.filter(
          (client) => client.cases.some((c) => c.executiveID === userId)
        );
        setClients(executiveClients);
        setFilteredClients(executiveClients); // Initialize filtered clients with all clients
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
      });

    // Fetch executive's data
    axios
      .get(`${config.backendapi}/api/cases/status`)
      .then((response) => {
        setExecutiveData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching executive data:", error);
      });
  }, []);

  useEffect(() => {
    // Filter clients based on selected client name and date range
    let filtered = clients;

    if (selectedClient !== "") {
      if (selectedClient === "all") {
        setFilteredClients(clients);
        return;
      }
      filtered = filtered.filter((client) => client.client === selectedClient);
    }

    if (startDate !== "" && endDate !== "") {
      filtered = filtered.filter((client) => {
        const clientJoiningDate = new Date(client.cases[0].joiningDate);
        return (
          clientJoiningDate >= new Date(startDate) &&
          clientJoiningDate <= new Date(endDate)
        );
      });
    }

    setFilteredClients(filtered);
  }, [selectedClient, startDate, endDate, clients]);

  const handleSelectChange = (event) => {
    setSelectedClient(event.target.value);
    // Reset start date and end date when the client dropdown is changed
    setStartDate("");
    setEndDate("");
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleClearFilter = () => {
    setSelectedClient("");
    setStartDate("");
    setEndDate("");
  };

  const exportToExcel = () => {
    const data = filteredClients.map((client) => ({
      Client: client.client,
      "Joining Date": new Date(client.cases[0].joiningDate).toLocaleDateString(),
      Lead: client.cases[0].reference ? "Yes" : "No",
      "onHold": client.statusCounts["onHold"],
      "onGoing Cases": client.statusCounts["onGoing"],
      "closed Cases": client.statusCounts["closed"],
      "Total Payment in Rupees": client.totalAmount,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Clients");
    XLSX.writeFile(wb, "client_report.xlsx");
  };

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div>
        <Sidebar />
      </div>
      <div className="clientreportconat min-h-screen w-full flex items-center justify-center bg-gray-100">
        <div className="mt-12 w-full max-w-screen-lg">
          <div className="p-4">
            <h1 className="text-3xl font-bold text-center mb-4">Client Report</h1>
            <div>
              {executiveData ? (
                <div>
                  <div className="md:flex md:space-x-4">
                    <div className="md:w-1/3 mb-4 md:mb-0">
                      <div className="card shadow custom-card bg-white border border-gray-300 hover:bg-blue-200">
                        <div className="card-body text-center">
                          <h5 className="card-title">On Hold</h5>
                          <p className="card-text mb-0 text-3xl">{executiveData["onHold"]}</p>
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/3 mb-4 md:mb-0">
                      <div className="card shadow custom-card bg-white border border-gray-300 hover:bg-blue-200">
                        <div className="card-body text-center">
                          <h5 className="card-title">On Going Cases</h5>
                          <p className="card-text mb-0 text-3xl">{executiveData["onGoing"]}</p>
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/3">
                      <div className="card shadow custom-card bg-white border border-gray-300 hover:bg-blue-200">
                        <div className="card-body text-center">
                          <h5 className="card-title">Closed Cases</h5>
                          <p className="card-text mb-0 text-3xl">{executiveData["closed"]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
              <div className="flex flex-col md:flex-row md:space-x-4 ">
                <div className="relative mb-2 md:mb-0 w-full md:w-auto">
                  <select
                    value={selectedClient}
                    onChange={handleSelectChange}
                    className="border border-gray-300 rounded-lg px-4 py-2.5 focus:outline-none focus:border-blue-500 w-full md:w-auto"
                  >
                    <option value="">Select Client</option>
                    <option value="all">All Clients</option>
                    {clients.map((client, index) => (
                      <option key={index} value={client.client}>
                        {client.client}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="relative mb-2 md:mb-0 w-full md:w-auto">
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    placeholder="Start Date"
                    className={`border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 ${
                      selectedClient !== "" ? "pointer-events-none bg-gray-300" : ""
                    } w-full md:w-auto`}
                  />
                </div>
                <div className="relative mb-2 md:mb-0 w-full md:w-auto">
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    placeholder="End Date"
                    className={`border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 ${
                      selectedClient !== "" ? "pointer-events-none bg-gray-300" : ""
                    } w-full md:w-auto`}
                  />
                </div>
              </div>
              <div>
                <button
                  onClick={handleClearFilter}
                  className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                >
                  Clear Filter
                </button>
              </div>
            </div>
            <div className="overflow-x-auto relative">
              <table id="client-report-table" className="table-fixed border border-gray-300 bg-white">
                <thead className="bg-blue-100">
                  <tr>
                    <th className="border-2 border-gray-300 px-2 py-2 w-1/6 text-sm">Client</th>
                    <th className="border-2 border-gray-300 px-2 py-2 w-1/6 text-sm">Joining Date</th>
                    <th className="border-2 border-gray-300 px-2 py-2 w-1/6 text-sm">onHold</th>
                    <th className="border-2 border-gray-300 px-2 py-2 w-1/6 text-sm">onGoing Cases</th>
                    <th className="border-2 border-gray-300 px-2 py-2 w-1/6 text-sm">closed Cases</th>
                    <th className="border-2 border-gray-300 px-2 py-2 w-1/6 text-sm">Total Payment in Rupees</th>
                  </tr>
                </thead>
                <tbody>
                  {currentClients.map((client, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 px-2 py-2 w-1/6 text-sm text-center">{client.client}</td>
                      <td className="border border-gray-300 px-2 py-2 w-1/6 text-sm text-center">
                        {new Date(client.cases[0].joiningDate).toLocaleDateString()}
                      </td>
                     
                      <td className="border border-gray-300 px-2 py-2 w-1/6 text-sm text-center">
                        {client.statusCounts["onHold"]}
                      </td>
                      <td className="border border-gray-300 px-2 py-2 w-1/6 text-sm text-center">
                        {client.statusCounts["onGoing"]}
                      </td>
                      <td className="border border-gray-300 px-2 py-2 w-1/6 text-sm text-center">
                        {client.statusCounts["closed"]}
                      </td>
                      <td className="border border-gray-300 px-2 py-2 w-1/6 text-sm text-center">{client.totalAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center mt-4 p-4">
              {clients.length > clientsPerPage && (
                <ul className="flex list-none">
                  {Array(Math.ceil(filteredClients.length / clientsPerPage))
                    .fill()
                    .map((_, index) => (
                      <li key={index}>
                        <button
                          className={`mx-1 px-3 py-1 border border-gray-300 rounded ${
                            currentPage === index + 1 ? "bg-blue-950 text-white font-bold" : ""
                          }`}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                </ul>
              )}
            </div>
            <div className="report-download p-4 flex justify-center mt-4">
              <button
                onClick={exportToExcel}
                className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
              >
                Export To Excel <FaFileDownload className="inline-block ml-2" />
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientReport;
