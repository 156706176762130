import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import config from '../BackendConfig/Config';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordPopup from './ForgotPasswordPopup';

const LoginPopUp = ({ setShowLoginPopUp }) => {
  const [currState, setCurrState] = useState('Login');
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currState === 'Login') {
      setLoading(true);
      try {
        const response = await axios.post(
          `${config.backendapi}/api/executives/login`,
          { email: formData.email, password: formData.password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = response.data;
        if (response.status !== 200) {
          throw new Error(data.error || "Login failed");
        }

        localStorage.setItem("token", data.token);
        localStorage.setItem("userId", data.userId);
        navigate('/executiveprofile');

        setError("");
        setShowLoginPopUp(false); // Close the popup on successful login
      } catch (error) {
        setError(error.message || "An error occurred while logging in");
      } finally {
        setLoading(false);
      }
    }
    // Add additional handling for Sign Up if needed
  };

  return (
    <div className="absolute inset-0 z-10 bg-black bg-opacity-60 grid place-items-center">
      {showForgotPasswordPopup && <ForgotPasswordPopup setShowForgotPasswordPopup={setShowForgotPasswordPopup} />}
      <form className="w-[23vw] min-w-[330px] max-w-sm bg-white text-gray-600 p-6 rounded-lg flex flex-col gap-6 animate-fadeIn" onSubmit={handleSubmit}>
        <div className="flex justify-between items-center text-black">
          <h2 className="text-xl font-semibold">{currState}</h2>
          <RxCross2 className="cursor-pointer" size={25} onClick={() => setShowLoginPopUp(false)} />
        </div>
        <div className="flex flex-col gap-5">
          <input
            type="email"
            name="email"
            placeholder="Your email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="outline-none p-2 border border-gray-300 rounded-md"
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
            required
            className="outline-none p-2 border border-gray-300 rounded-md"
          />
        </div>
        {error && <div className="text-red-500">{error}</div>}
        <button
          type="submit"
          className="border-none p-2 rounded-md text-white bg-[#01004C] bg-tomato font-medium cursor-pointer"
          disabled={loading}
        >
          {loading ? 'Logging in...' : currState === 'Sign Up' ? 'Create account' : 'Login'}
        </button>
        <div className="flex items-start gap-2 mt-[-15px]">
          <input type="checkbox" required className="mt-1" />
          <p>By continuing, I agree to the terms of use & privacy policy.</p>
        </div>
        {currState === 'Login' ? (
          <p>
            <span className="text-[#01004C] font-medium cursor-pointer" onClick={() => setShowForgotPasswordPopup(true)}>
              Forgot Password
            </span>
          </p>
        ) : (
          <p>
            Already have an account?{' '}
            <span className="text-[#01004C] font-medium cursor-pointer">
              Login here
            </span>
          </p>
        )}
      </form>
    </div>
  );
};

export default LoginPopUp;
