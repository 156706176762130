import React from "react";
import Img1 from "./images/1.jpeg"
import Img2 from "./images/2.jpeg"
import Img3 from "./images/3.png"

import Navbar from "./Navbar";
import Footer from "./Footer";

const AboutUs = () => {
  return (

  <>
  <Navbar/>
      <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Hero section */}

        <div className="flex flex-wrap items-center justify-around mt-12">
          <div className="w-full md:w-1/2 lg:w-1/3 mb-8 md:mb-0">
            {/* Placeholder image or icon */}
            <img
              src={Img1}
              alt={"img"}
              className="w-[90%] h-64 object-cover object-center rounded-lg me-5"
            />

            {/* <div className="bg-gray-300 rounded-lg h-64 me-5"></div> */}
          </div>
          <div className="w-full md:w-1/2 lg:w-2/3 mb-8 md:mb-0 ">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              About Vastu
            </h2>
            <p className="text-lg text-gray-600 leading-relaxed ">
              Vastu is dedicated to bringing harmony and balance through ancient
              architectural principles.
            </p>
          </div>
        </div>

        {/* Mission section */}
        <div className="flex flex-wrap-reverse items-center justify-around mt-12">
          <div className="w-full md:w-1/2 lg:w-2/3 mb-8 md:mb-0">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              Our Mission
            </h2>
            <p className="text-lg text-gray-600 leading-relaxed ">
              Our mission is to promote the principles of Vastu Shastra, guiding
              people towards living in harmonious environments.
            </p>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 mb-8 md:mb-0">
          <img
              src={Img2}
              alt={"img"}
              className="w-[90%] h-64 object-cover object-center rounded-lg me-5"
            />
          </div>
        </div>

        {/* Vision section */}
        <div className="flex flex-wrap items-center justify-around mt-12">
          <div className="w-full md:w-1/2 lg:w-1/3 mb-8 md:mb-0">
          <img
              src={Img3}
              alt={"img"}
              className="w-[90%] h-64 object-cover object-center rounded-lg me-5"
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-2/3 mb-8 md:mb-0">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              Our Vision
            </h2>
            <p className="text-lg text-gray-600 leading-relaxed ">
              To be the leading platform for Vastu consultancy, education, and
              products, enhancing lives through holistic living spaces.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>
  );
};

export default AboutUs;
