import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import "./CasesMR.css"
import config from "../BackendConfig/Config"

const ProductDetails = ({ initialPurchased }) => {
  const [cases, setCases] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedCase, setSelectedCase] = useState('');
  const [productDetails, setProductDetails] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const response = await axios.get(`${config.backendapi}/api/cases`);
        const casesData = response.data.data;
        const userCases = casesData.filter((caseItem) => caseItem.executiveID === userId);
        setCases(userCases);
        const uniqueClients = Array.from(
          new Set(userCases.map((caseItem) => JSON.stringify({
            client_id: caseItem.client_id,
            client: caseItem.client
          })))
        ).map(client => JSON.parse(client));
        setClients(uniqueClients);
      } catch (error) {
        console.error('Error fetching cases:', error);
      }
    };
    fetchCases();
  }, []);

  const fetchProductDetails = async (clientId, caseId) => {
    try {
      const response = await axios.get(`${config.backendapi}/api/addproduct`);
      const allProductDetails = response.data;
      const filteredProducts = allProductDetails.filter(product => product.clientID === clientId && product.CaseID === caseId);
      console.log('Fetched Products:', filteredProducts); // Initial state logging
      setProductDetails(filteredProducts);
      const initialSelectedProducts = filteredProducts.map(product => ({
        ...product,
        purchased: product.purchased ?? initialPurchased
      }));
      setSelectedProducts(initialSelectedProducts);
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  const handleSelectChange = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    setSelectedCase('');
    setProductDetails([]);
  };

  const handleCaseSelectChange = (event) => {
    const caseId = event.target.value;
    setSelectedCase(caseId);
    fetchProductDetails(selectedClient, caseId);
  };

  const handleCheckboxChange = (index) => {
    console.log("Checkbox change request for product at index:", index);
    console.log("Before change:", selectedProducts);

    setSelectedProducts(prevProducts => {
      const updatedProducts = prevProducts.map((product, i) => 
        i === index ? { ...product, purchased: !product.purchased } : product
      );

      console.log("After change:", updatedProducts);
      return updatedProducts;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const purchasedProductIds = selectedProducts.filter(product => product.purchased).map(product => product._id);
      const dataToSend = { ids: purchasedProductIds };
      console.log("Data to send:", dataToSend); // Log data before sending

      await axios.patch(`${config.backendapi}/api/addproduct/updatePurchased`, dataToSend);
      alert('Products updated successfully');
    } catch (error) {
      console.error('Error updating products:', error);
      console.error('Error response:', error.response?.data || error.message);
      alert('Error updating products');
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="viewcases flex flex-col justify-center items-center min-h-screen w-full bg-gray-100">
        <div className="w-full sm:w-1/2 md:w-1/3 border-2 border-blue-950 bg-white rounded-lg mt-16">
          <div className="rounded px-8 pt-6 pb-8 mb-4">
            <h2 className="text-3xl font-bold mb-4">Add Product Details</h2>
            <div className="mb-4">
              <label htmlFor="client" className="block text-gray-700 text-sm font-bold mb-2">
                Choose Client
              </label>
              <select
                id="client"
                name="client"
                className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                value={selectedClient}
                onChange={handleSelectChange}
              >
                <option value="">Select Client</option>
                {clients.map((client) => (
                  <option key={client.client_id} value={client.client_id}>
                    {client.client}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="caseID" className="block text-gray-700 text-sm font-bold mb-2">
                Choose Case
              </label>
              <select
                id="caseID"
                name="caseID"
                className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={handleCaseSelectChange}
                value={selectedCase}
                disabled={!selectedClient}
              >
                <option value="">Select Case</option>
                {cases
                  .filter((ca) => ca.client_id === selectedClient)
                  .map((ca) => (
                    <option key={ca._id} value={ca._id}>
                      {ca.caseLabel}
                    </option>
                  ))}
              </select>
            </div>
            <div className="overflow-x-auto">
              <table className="table-fixed border border-gray-400">
                <thead>
                  <tr>
                    <th className="border border-gray-400 px-2 py-2 w-1/6 text-sm">Name</th>
                    <th className="border border-gray-400 px-2 py-2 w-1/6 text-sm">Category</th>
                    <th className="border border-gray-400 px-2 py-2 w-1/6 text-sm">Priority</th>
                    <th className="border border-gray-400 px-2 py-2 w-1/6 text-sm">Purchased</th>
                    <th className="border border-gray-400 px-2 py-2 w-1/6 text-sm">Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts.map((product, index) => (
                    <tr key={product._id}>
                      <td className="border border-gray-400 px-2 py-2 w-1/6 text-sm">{product.productName}</td>
                      <td className="border border-gray-400 px-2 py-2 w-1/6 text-sm">{product.productCategory}</td>
                      <td className="border border-gray-400 px-2 py-2 w-1/6 text-sm">{product.priority ? 'Yes' : 'No'}</td>
                      <td className="border border-gray-400 px-2 py-2 w-1/6 text-sm">
                        <input
                          type="checkbox"
                          checked={product.purchased}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>
                      <td className="border border-gray-400 px-2 py-2 w-1/6 text-sm">{product.paymentStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="report-download p-4 flex justify-center">
              <button
                className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-4"
                onClick={handleSubmit}
              >
                Save Purchased
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
