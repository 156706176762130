import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios'; // Import Axios for making HTTP requests
import moment from 'moment'; // Import Moment.js for date formatting
import "./AddFeedback.css"
import config from "../BackendConfig/Config"

const IndividualClients = () => {

  const { id } = useParams();
  const navigate = useNavigate(); // Use the useNavigate hook
  const [clientDetails, setClientDetails] = useState(null);
  const [editMode, setEditMode] = useState(false); // State to manage edit mode
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // State variables for edited client data
  const [editedClient, setEditedClient] = useState({
    firstName: '',
    joiningDate: '',
    phoneNumber: '',
    address: '',
    city: '',
    feedback: '',
    callDetails: ''
    // Add other fields as needed
  });

  // Function to fetch client details based on client ID
  useEffect(() => {
    axios.get(`${config.backendapi}/api/clients/${id}`)
      .then(response => {
        setClientDetails(response.data.data); // Assuming the client data is nested under the 'data' key
        // Populate edited client data with fetched details
        setEditedClient({
          firstName: response.data.data.firstName,
          phoneNumber: response.data.data.phoneNumber,
          joiningDate: response.data.data.joiningDate ? moment(response.data.data.joiningDate).format('YYYY-MM-DD') : '',
          address: response.data.data.address,
          city: response.data.data.city,
          feedback: response.data.data.feedback,
          callDetails: response.data.data.callDetails
          // Populate other fields as needed
        });
      })
      .catch(error => {
        console.error(`Error fetching client details: ${error}`);
      });
  }, [id]);

  // Function to handle input change in edit mode
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Validation for joining date
    if (name === 'joiningDate' && !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      setErrorMessage('Invalid date format (YYYY-MM-DD)');
      setTimeout(() => setErrorMessage(''), 3000);
      return;
    }
    // Validation for city
    if (name === 'city' && !/^[a-zA-Z\s]+$/.test(value)) {
      setErrorMessage('City can only contain letters and spaces');
      setTimeout(() => setErrorMessage(''), 3000);
      return;
    }
    setEditedClient(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSaveChanges = () => {
    // Check if any changes were made
    const changesMade = Object.keys(editedClient).some(key => editedClient[key] !== clientDetails[key]);

    if (!changesMade) {
      setErrorMessage('No changes made.');
      setTimeout(() => setErrorMessage(''), 3000); // Clear error message after 3 seconds
      return;
    }

    // Send PATCH request to update data
    axios.patch(`${config.backendapi}/api/clients/${id}`, editedClient)
      .then(response => {
        setSuccessMessage('Client profile updated successfully');
        setTimeout(() => setSuccessMessage(''), 3000); // Clear success message after 3 seconds
        setEditMode(false); // Exit edit mode
      })
      .catch(error => {
        console.error('Error updating data:', error);
        setErrorMessage('Failed to update data');
        setTimeout(() => setErrorMessage(''), 3000); // Clear error message after 3 seconds
      });
  };

  // Function to handle cancel changes
  const handleCancelChanges = () => {
    setEditMode(false); // Exit edit mode
    // Reset edited client data to original client details
    setEditedClient({
      firstName: clientDetails.firstName,
      joiningDate: clientDetails.joiningDate ? moment(clientDetails.joiningDate).format('YYYY-MM-DD') : '',
      address: clientDetails.address,
      city: clientDetails.city,
      feedback: clientDetails.feedback,
      callDetails: clientDetails.callDetails
      // Reset other fields as needed
    });
  };

  // Function to handle back button click
  const handleBackClick = () => {
    navigate('/viewclients');
  };

  if (!clientDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <Sidebar />
      </div>
      <div className="maindivrespons flex flex-col justify-center items-center min-h-screen w-full bg-gray-100">
        <div className="w-full sm:w-1/2 md:w-1/3 border-2 border-blue-950 bg-white rounded-lg mt-16">
          <div className="rounded px-8 pt-6 pb-8 mb-4">
            <h1 className="text-2xl font-bold text-center mb-4">{clientDetails.firstName} {clientDetails.lastName}</h1>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="callDetails">
                  Phone Number
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
                  id="callDetails"
                  name="callDetails"
                  type="text"
                  placeholder="Call Details"
                  value={editedClient.phoneNumber}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="joiningDate">
                  Joining Date
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
                  id="joiningDate"
                  name="joiningDate"
                  type="date"
                  placeholder="Date (YYYY-MM-DD)"
                  value={editedClient.joiningDate}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                  Address
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
                  id="address"
                  name="address"
                  type="text"
                  placeholder="Location"
                  value={editedClient.address}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="city">
                  City
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
                  id="city"
                  name="city"
                  type="text"
                  placeholder="Location"
                  value={editedClient.city}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="feedback">
                  Feedback
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
                  id="feedback"
                  name="feedback"
                  type="text"
                  placeholder="Feedback"
                  value={editedClient.feedback}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="callDetails">
                  Call Details
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${editMode ? '' : 'bg-gray-200'}`}
                  id="callDetails"
                  name="callDetails"
                  type="text"
                  placeholder="Call Details"
                  value={editedClient.callDetails}
                  onChange={handleInputChange}
                  readOnly={!editMode}
                />
              </div>
              {editMode ? (
                <div className="flex justify-center">
                  <button
                    className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4 mr-2"
                    type="button"
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </button>
                  <button
                    className="bg-red-600 hover:bg-red-400 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4"
                    type="button"
                    onClick={handleCancelChanges}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="flex justify-center gap-10">
                  <button
                    className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4"
                    type="button"
                    onClick={toggleEditMode}
                  >
                    Edit Client
                  </button>
                  <button
                    className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4"
                    type="button"
                    onClick={handleBackClick} 
                  >
                    Back
                  </button>
                </div>
              )}
            </form>
            <div>
              {successMessage && <p className="text-gray-700 text-center">{successMessage}</p>}
              {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualClients;
