import React, { useState, useEffect } from 'react';
import '../Login/Login.css';
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import "./UpdatePass.css";
import config from "../BackendConfig/Config";

const UpdatePass = () => {
  const [executiveId, setExecutiveId] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const id = localStorage.getItem('userId');
    if (id) {
      setExecutiveId(id);
    }
  }, []);

  const handleUpdatePass = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!executiveId || !oldPassword || !newPassword) {
      setMessage('Please fill in all fields.');
      return;
    }

    console.log('Request payload:', {
      executiveId,
      oldPassword,
      newPassword
    });

    try {
      const response = await fetch(`${config.backendapi}/api/executives/change-pass`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          executiveId: executiveId,
          oldPassword: oldPassword,
          newPassword: newPassword,
        }),
      });

      const data = await response.json();
      console.log('Response:', data);

      if (response.ok) {
        alert(data.message)
        setMessage(data.message);
        // Redirect to ExecutiveProfile page
        navigate("/executiveprofile");
      } else {
        // alert(data.message)
        setMessage(data.message || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div>
      <div>
        <Sidebar />
      </div>
      <div className="updatepasscont flex flex-col justify-center items-center min-h-screen w-full bg-gray-100">
        <div className="updatepasscontborder w-full sm:w-1/2 md:w-1/3 border-2 border-blue-950 bg-white rounded-lg">
          <div className="rounded px-8 pt-6 pb-8 mb-4 mt-10">
            <h1 className="text-2xl font-bold text-center mb-4">Update Password</h1>
            <form onSubmit={handleUpdatePass}>
              <div className="EmailPasswordPlaceholder">
                <div className='m-4'>
                  <input 
                    type="password" 
                    value={oldPassword} 
                    onChange={(e) => setOldPassword(e.target.value)} 
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder='Old password'
                  />
                </div>
                <div className='m-4'>
                  <input 
                    type="password" 
                    value={newPassword} 
                    onChange={(e) => setNewPassword(e.target.value)} 
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder='New password'
                  />
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <button 
                  type="submit"                   
                  className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mr-4"
                >
                  Update Password
                </button>
              </div>
              <div className='flex justify-center mt-2'>
                {message && <p>{message}</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePass;
