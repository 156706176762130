import React, { useState, useEffect } from 'react';
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import config from '../BackendConfig/Config';

const ForgotPasswordPopup = ({ setShowForgotPasswordPopup }) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [timeRemaining, setTimeRemaining] = useState(300); // 5 minutes in seconds

  useEffect(() => {
    if (step === 2) {
      const timer = setInterval(() => {
        setTimeRemaining(prev => (prev > 0 ? prev - 1 : 0));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [step]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {

      const payload = { email };
      console.log("payload", payload);
      const response = await axios.post(
        `${config.backendapi}/api/admin/forgot-pass`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      if (response.status === 200) {
        setMessage("OTP sent to your email");
        setError('');
        setStep(2);
      } else {
        throw new Error(response.data.error || "Failed to send OTP");
      }
    } catch (error) {
      setError(error.message || "An error occurred while sending OTP");
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.backendapi}/api/admin/change-pass`,
        { email, otp, newPassword },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      if (response.status === 200) {
        setMessage("Password reset successfully");
        setError('');
        setShowForgotPasswordPopup(false);
      } else {
        throw new Error(response.data.error || "Failed to reset password");
      }
    } catch (error) {
      setError(error.message || "An error occurred while resetting password");
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="absolute inset-0 z-10 bg-black bg-opacity-60 grid place-items-center">
      <form className="w-[23vw] min-w-[330px] max-w-sm bg-white text-gray-600 p-6 rounded-lg flex flex-col gap-6 animate-fadeIn" onSubmit={step === 1 ? handleSendOtp : handleVerifyOtp}>
        <div className="flex justify-between items-center text-black">
          <h1 className="text-2xl font-semibold">{step === 1 ? 'Verify your Email account' : 'Enter OTP'}</h1>
          <RxCross2 className="cursor-pointer" size={25} onClick={() => setShowForgotPasswordPopup(false)} />
        </div>
        {step === 1 ? (
          <>
            <p className="mb-4">Your email</p>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              required
              className="w-full p-2 border border-gray-300 rounded mb-4"
              placeholder="Enter your email"
            />
            {error && <div className="text-red-500">{error}</div>}
            {message && <div className="text-green-500">{message}</div>}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? 'Sending OTP...' : 'Send OTP'}
            </button>
          </>
        ) : (
          <>
            <p className="mb-4">Enter OTP</p>
            <input
              type="text"
              value={otp}
              onChange={handleOtpChange}
              required
              className="w-full p-2 border border-gray-300 rounded mb-4"
              placeholder="Enter OTP"
            />
            <p className="mb-4">New Password</p>
            <input
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              required
              className="w-full p-2 border border-gray-300 rounded mb-4"
              placeholder="Enter new password"
            />
            {error && <div className="text-red-500">{error}</div>}
            {message && <div className="text-green-500">{message}</div>}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? 'Verifying OTP...' : 'Verify OTP'}
            </button>
            <div className="mt-2 text-gray-600">Time remaining: {Math.floor(timeRemaining / 60)}:{(timeRemaining % 60).toString().padStart(2, '0')}</div>
          </>
        )}
      </form>
    </div>
  );
};

export default ForgotPasswordPopup;
