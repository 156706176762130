import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FaUsers, FaUser } from 'react-icons/fa';
import { RiChatFollowUpFill } from "react-icons/ri";
import { HiDocumentReport } from "react-icons/hi";
import { IoLogOut } from "react-icons/io5"; // Import IoLogOut
import './Sidebar.css';
import logo from "../../Assets/pandit_shivkumar_logo.png"
import config from "../BackendConfig/Config"


const Sidebar = () => {
  const [isActive, setIsActive] = useState(true);
  const [activeItem, setActiveItem] = useState('');
  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isCaseDropdownOpen, setIsCaseDropdownOpen] = useState(false);
  const [executiveName, setExecutiveName] = useState('');
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const toggleClientDropdown = () => {
    setIsClientDropdownOpen(!isClientDropdownOpen);
    setIsCaseDropdownOpen(false); // Close case dropdown
  };

  const toggleCaseDropdown = () => {
    setIsCaseDropdownOpen(!isCaseDropdownOpen);
    setIsClientDropdownOpen(false); // Close client dropdown
  };

  const closeSidebar = () => {
    setIsActive(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      const response = await fetch(
        `${config.backendapi}/api/executives/${userId}`
      );
      const data = await response.json();
      setExecutiveName(`${data.firstName} ${data.lastName}`); // Setting the executive name
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []); // Empty dependency array to fetch data only once on component mount

  return (
    <div>
      <div className={`sidebar ${isActive ? 'active' : ''}`}>
        <div className="sidebar_header">
          <div className='sidebar-ext-icon'>
            <FaUser size="2x" />
          </div>
          <div className='sidebar-view-profile'>
            <p className='text-sm'>{executiveName}</p> {/* Displaying executive name */}
            <Link
              to="/executiveprofile"
              className={`sidebar_item_profile ${activeItem === 'profile' ? 'active' : ''}`}
              onClick={() => { closeSidebar(); handleItemClick('profile'); }}
            >
              <h4>View Profile</h4>
            </Link>
          </div>
        </div>

        <div className="sidebar_menu">
          <div className={`sidebar_item ${activeItem === 'clients' ? 'active' : ''}`} onClick={toggleClientDropdown}>
            <div className="sidebar_icon">
              <FaUsers />
            </div>
            <h4>Client <FontAwesomeIcon icon={faCaretDown} /></h4>
            {isClientDropdownOpen && (
              <div className="dropdown">
                <ul className="dropdown-menu">
                  <li className='dropdown-menu-li'>
                    <Link to="/viewclients" onClick={() => { closeSidebar(); handleItemClick('clients'); }}>View Client</Link>
                  </li>
                  <li className='dropdown-menu-li'>
                    <Link to="/addfeedback" onClick={() => { closeSidebar(); handleItemClick('addFeedback'); }}>Add Message</Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className={`sidebar_item ${activeItem === 'cases' ? 'active' : ''}`} onClick={toggleCaseDropdown}>
            <div className="sidebar_icon">
              <FaUsers />
            </div>
            <h4>Case <FontAwesomeIcon icon={faCaretDown} /></h4>
            {isCaseDropdownOpen && (
              <div className="dropdown">
                <ul className="dropdown-menu">
                  <li className='dropdown-menu-li'>
                    <Link to="/viewcases" onClick={() => { closeSidebar(); handleItemClick('cases'); }}>View Case</Link>
                  </li>
                  <li className='dropdown-menu-li'>
                    <Link to="/productdetails" onClick={() => { closeSidebar(); handleItemClick('productDetails'); }}>Product Details</Link>
                  </li>
                  <li className='dropdown-menu-li'>
                    <Link to="/addfallowup" onClick={() => { closeSidebar(); handleItemClick('addFollowUp'); }}>Add follow up</Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <Link
            to="/clientreport"
            className={`sidebar_item ${activeItem === 'report' ? 'active' : ''}`}
            onClick={() => { closeSidebar(); handleItemClick('report'); }}
          >
            <div className="sidebar_icon">
              <HiDocumentReport />
            </div>
            <h4>Report</h4>
          </Link>


{/* Testing api for admin and executive not used in project only testing purpose  */}
          {/* <Link
            to="/addexecutive"
            className={`sidebar_item ${activeItem === 'report' ? 'active' : ''}`}
            onClick={() => { closeSidebar(); handleItemClick('report'); }}
          >
            <div className="sidebar_icon">
              <HiDocumentReport />
            </div>
            <h4>Add Executive</h4>
          </Link>

          <Link
            to="/addnewexecutive"
            className={`sidebar_item ${activeItem === 'report' ? 'active' : ''}`}
            onClick={() => { closeSidebar(); handleItemClick('report'); }}
          >
            <div className="sidebar_icon">
              <HiDocumentReport />
            </div>
            <h4>Add New Executive</h4>
          </Link> */}
{/* Testing api for admin and executive not used in project only testing purpose  */}




          <Link
            to="/upcomingFollowup"
            className={`sidebar_item ${activeItem === 'upcomingFollowup' ? 'active' : ''}`}
            onClick={() => { closeSidebar(); handleItemClick('upcomingFollowup'); }}
          >
            <div className="sidebar_icon">
              <RiChatFollowUpFill />
            </div>
            <h4>Upcoming Follow up</h4>
          </Link>
          <div className="sidebar_item" onClick={handleLogout}>
            <div className="sidebar_icon">
              <IoLogOut />
            </div>
            <h4>Logout</h4>
          </div>
        </div>
      </div>

      <div className={`container-sidebar ${isActive ? 'active_margin_left' : ''}`}>
        <div className="header">
          <div>
            <button className="btn-toggle" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <div className='sidebar-log'>
            <img src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
