import React,{useState} from 'react'
import Navbar from './Navbar';
import Footer from './Footer';


const Blog = () => {

    const blogPosts = [
        {
          id: 1,
          title: 'Health 2023, Keep the healthy energy flowing in your house, Vastu guide',
          description: 'It feels good when clean energy flows freely around you. When you feel good, you can feel the energy and resonance lightly. Lighter resonance gives you a sense of vitality and joyfulness. This resonance creates health. As a result, people will have fewer visits to doctors and hospitals.When you walk into a person\’s home, you can almost immediately feel their energy and vibe. The science of Vastu helps us see the unseen forces around us by analyzing the space we occupy and how that space impacts our lives.',
          image: 'https://vastuconsultant.com/wp-content/uploads/2022/01/health-home-vastu-healthy-1024x460.jpg'
        },
        {
          id: 2,
          title: 'How to buy a home that would bring a good fortune?',
          description: 'Those who are attuned to their surroundings are sure to have experienced either a comforting and strong feeling when stepping into a house and they might also have felt anxious or off-balance when entering some other property.  It is pretty common to say, “oh god, this house gives off bad vibes” or “Wow, I love how homey & comfortable this house feels”.',
          image: 'https://vastuconsultant.com/wp-content/uploads/2021/11/good-fortune-vastu-home.jpg', // Placeholder image URL
        },
        {
          id: 3,
          title: 'Reset & upgrade your house-Renovating a house-Vastu',
          description: 'A guest or visitor may be asking you “so, when are you going to flip this home”. Think renovation of home like a  surgery.  A true upgrade to your house means leaving the structure of the house as they are and replacing the fittings, artwork, paint, flooring, wiring, plumbing,  and also displays.',
          image: 'https://vastuconsultant.com/wp-content/uploads/2018/04/house-reset-renovation-vastu-plan.jpg', // Placeholder image URL
        },
      ];
      const [visiblePosts, setVisiblePosts] = useState({});
    
      const handleReadMore = (id) => {
        setVisiblePosts((prevVisiblePosts) => ({
          ...prevVisiblePosts,
          [id]: !prevVisiblePosts[id],
        }));
      };
    
      const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        return words.length > wordLimit ? `${words.slice(0, wordLimit).join(' ')}...` : text;
      };
  return (
    <>
    <Navbar/>

<div className="p-3  bg-gray-100 -mt-2 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <div className='text-center text-4xl font-bold mb-4'>Blogs</div>
      <div className=" grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
        {blogPosts.map((post) => (
          <div key={post.id} className="bg-white lg:flex lg:items-center rounded-lg shadow-md overflow-hidden">
            <img src={post.image} alt={post.title} className="lg:w-[40%] w-full h-60 object-cover object-center" />
            <div className="p-6 lg:w-[50%] lg:ms-12">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 object-cover">{post.title}</h2>
              <p className="text-gray-600">
                {visiblePosts[post.id] ? post.description : truncateText(post.description, 20)}
              </p>
              <button
                onClick={() => handleReadMore(post.id)}
                className="block mt-4 text-sm font-medium text-tomato hover:text-red-700 transition duration-300"
              >
                {visiblePosts[post.id] ? 'Read less' : 'Read more'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>

<Footer/>

    </>
  )
}

export default Blog