import React from 'react'
import { TiSocialFacebook } from "react-icons/ti";
import { SlSocialInstagram, SlSocialLinkedin } from "react-icons/sl";
import { SlSocialTwitter } from "react-icons/sl";
import Logo from '../../Assets/pandit_shivkumar_logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
  <>

<div className="text-gray-300 bg-[#01004C] flex flex-col items-center gap-5 p-5 pt-20 md:px-[8vw]">
      <div className="w-full grid grid-cols-1 md:grid-cols-[2fr_1fr_1fr] gap-20 md:gap-8">
        <div className="flex flex-col items-start gap-5">
          <img src={Logo} alt="logo" className="w-35" />
          <p>
            Our mission is to delight your senses with a diverse array of flavors and culinary experiences. Explore our recipes, indulge in our stories, and join us on a journey of gastronomic exploration.
          </p>
          <div className="flex gap-4 cursor-pointer">
            <TiSocialFacebook size={25} />
            <SlSocialInstagram size={25} />
            <SlSocialTwitter size={25} />
            <SlSocialLinkedin size={25} />
          </div>
        </div>
        <div className="flex flex-col items-start gap-5">
          <h2 className="text-white">COMPANY</h2>
          <ul className="space-y-2">
            <li className="list-none cursor-pointer" >
              <Link to='/'>
              Home
              </Link>
              </li>
            <li className="list-none cursor-pointer" >
            <Link to='/blogs'>
            Blogs
            </Link>
            </li>
            <li className="list-none cursor-pointer" >
            <Link to='/aboutus'>
            About Us
            </Link>
            
            </li>
            <li className="list-none cursor-pointer" >
            <Link to='/contactus'>
            Contact Us
            </Link>
            
            </li>
            
          </ul>
        </div>
        <div className="flex flex-col items-start gap-5">
          <h2 className="text-white">GET IN TOUCH</h2>
          <ul className="space-y-2">
            <li className="list-none">+91-222-666-5555</li>
            <li className="list-none">Contact@tomato.com</li>
          </ul>
        </div>
      </div>
      <hr className="w-full h-0.5 my-5 bg-gray-500 border-none" />
      <p className="text-center">Copyright 2024 @ vastu.com - All Right Reserved</p>
    </div>


  </>
  )
}

export default Footer