import React from 'react';
import { Link } from 'react-router-dom';
import { IoHomeSharp } from "react-icons/io5";
import { MdOutlineMenuBook } from "react-icons/md";
import { IoIosContact } from "react-icons/io";
import { TiInfoLarge } from "react-icons/ti";

const Sidebar = ({ setShowSidebar, showSidebar }) => {
  return (
    <div className={`absolute top-[8%] w-[250px] h-full bg-white text-[#01004C] z-[10000] transition-transform duration-700 ${showSidebar ? 'translate-x-0' : '-translate-x-full'}`}>
      <div className="p-5 pl-[10vw]">
        <ul>
          <Link to='/' className="flex items-center gap-2 mb-4 border border-[#01004C] p-2 rounded-md hover:bg-[#fff4f2]" onClick={() => setShowSidebar(!showSidebar)}>
            <IoHomeSharp size={22} />Home
          </Link>
          <Link to='/blogs' className="flex items-center gap-2 mb-4 border border-[#01004C] p-2 rounded-md hover:bg-[#fff4f2]" onClick={() => setShowSidebar(!showSidebar)}>
            <MdOutlineMenuBook size={22} />Blogs
          </Link>
          <Link to='/contactus' className="flex items-center gap-2 mb-4 border border-[#01004C] p-2 rounded-md hover:bg-[#fff4f2]" onClick={() => setShowSidebar(!showSidebar)}>
            <IoIosContact size={22} />Contact Us
          </Link>
          <Link to='/aboutus' className="flex items-center gap-2 mb-4 border border-[#01004C] p-2 rounded-md hover:bg-[#fff4f2]" onClick={() => setShowSidebar(!showSidebar)}>
            <TiInfoLarge size={22} />About Us
          </Link>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar;
