import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import Sidebar from '../Sidebar/Sidebar';
import "./CasesMR.css"
import config from "../BackendConfig/Config"


const ViewCases = () => {
  const [cases, setCases] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const casesPerPage = 4;

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    // Fetch cases
    axios.get(`${config.backendapi}/api/cases`)
      .then(response => {
        const executiveCases = response.data.data.filter(caseItem => caseItem.executiveID === userId);
        setCases(executiveCases);

        // Extract unique client names from cases
        const uniqueClients = [...new Set(executiveCases.map(caseItem => caseItem.client))];
        setClients(uniqueClients);
      })
      .catch(error => console.error('Error fetching cases:', error));
  }, []);

  const handleSelectChange = (event) => {
    setSelectedClient(event.target.value === 'all' ? '' : event.target.value);
    setCurrentPage(1); // Reset pagination when a new client is selected
  };

  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = cases
    .filter(caseItem => (!selectedClient || caseItem.client === selectedClient))
    .slice(indexOfFirstCase, indexOfLastCase);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div>
        <Sidebar />
      </div>
      <div className="viewcases min-h-screen w-full flex justify-center bg-gray-100 ">
        <div className="w-full max-w-xl mt-10">
          <div className="p-4">
            <h1 className="text-3xl font-bold text-center mb-4">View Cases</h1>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <select
                  className="w-full mb-4 md:w-auto mr-2 px-2 py-1 border border-gray-300 rounded"
                  value={selectedClient}
                  onChange={handleSelectChange}
                >
                  <option value="">Choose Client</option>
                  <option value="all">All Cases</option>
                  {clients.map((client, index) => (
                    <option key={index} value={client}>
                      {client}
                    </option>
                  ))}
                </select>
              </div>
              {currentCases.map((caseItem) => (
                <div
                  key={caseItem._id}
                  className="flex flex-col md:flex-row justify-between p-5 border-b border-blue-950"
                >
                  <div>
                    <label className="text-xl font-bold text-gray-800">
                      {caseItem.caseLabel}
                    </label>
                    <p className="text-gray-600">Client: {caseItem.client}</p>
                    <p className="text-gray-600">Issues: {caseItem.issues}</p>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <Link
                      to={`/individualcases/${caseItem._id}`}
                      className="w-full md:w-auto bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                    >
                      View Details
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-4 p-4">
              <ul className="flex list-none">
                {cases
                  .filter(caseItem => (!selectedClient || caseItem.client === selectedClient))
                  .length > casesPerPage &&
                  Array.from(
                    { length: Math.ceil(
                      cases
                        .filter(caseItem => (!selectedClient || caseItem.client === selectedClient))
                        .length / casesPerPage
                    ) },
                    (_, index) => (
                      <li key={index}>
                        <button
                          className={`mx-1 px-3 py-1 border border-gray-300 rounded ${
                            currentPage === index + 1 ? 'bg-blue-950 text-white font-bold' : ''
                          }`}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    )
                  )
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCases;
