

const config = {
  // Local server api 
  // backendapi : "http://localhost:8888"

  // Server side api 
  backendapi : "https://backend.piyushshivkumarshhri.com"
}

export default config;