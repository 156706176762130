import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import "./AddFeedback.css";
import config from "../BackendConfig/Config"


const AddMessage = () => {
  const [cases, setCases] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedCase, setSelectedCase] = useState(""); // Add state for selected case
  const [formData, setFormData] = useState({
    clientID: "",
    caseID: "",
    message: ""
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const response = await axios.get(`${config.backendapi}/api/cases`);
        const casesData = response.data.data;
        const userCases = casesData.filter((caseItem) => caseItem.executiveID === userId);
        setCases(userCases);
        const uniqueClients = Array.from(
          new Set(userCases.map((caseItem) => JSON.stringify({
            client_id: caseItem.client_id,
            client: caseItem.client
          })))
        ).map(client => JSON.parse(client));
        setClients(uniqueClients);
      } catch (error) {
        console.error('Error fetching cases:', error);
      }
    };
    fetchCases();
  }, []);

  const handleSelectChange = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    setSelectedCase(""); // Reset selected case when client changes
    setFormData({ ...formData, clientID: clientId, caseID: "" }); // Update form data
  };

  const handleCaseSelectChange = (event) => {
    const caseId = event.target.value;
    setSelectedCase(caseId);
    setFormData({ ...formData, caseID: caseId }); // Update form data
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async () => {
    try {
      const { caseID, clientID, message } = formData;

      // Validate required fields
      if (!clientID || !caseID || !message) {
        console.error("Missing required fields");
        return;
      }

      // Send feedback data to the backend
      const response = await axios.post(
        `${config.backendapi}/api/feedback/savefeedback`,
        {
          clientID: clientID,
          caseID: caseID,
          feedback: message
        }
      );

      // Check response status
      if (response.status === 201) {
        setMessage("Message added successfully");
        // Reset the form after successful submission
        setFormData({
          clientID: "",
          caseID: "",
          message: ""
        });
        setSelectedClient(""); // Reset selected client
        setSelectedCase(""); // Reset selected case
      } else {
        setMessage("Failed to store message");
      }

      // Display the message for 3 seconds
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } catch (error) {
      setMessage("Error storing message");
      console.error("Error storing message:", error);
    }
  };

  return (
    <div>
      <div>
        <Sidebar />
      </div>
      <div className="maindivrespons flex flex-col justify-center items-center min-h-screen w-full bg-gray-100">
        <div className="maindivrespons w-full sm:w-1/2 md:w-1/3 border-2 border-blue-950 bg-white rounded-lg">
          <div className="rounded px-8 pt-6 pb-8 mb-4 mt-10">
            <h1 className="text-2xl font-bold text-center mb-4">Add message</h1>
            <div className="mb-4">
              <label htmlFor="client" className="block text-gray-700 text-sm font-bold mb-2">
                Choose Client
              </label>
              <select
                id="client"
                name="client"
                className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                value={selectedClient}
                onChange={handleSelectChange}
              >
                <option value="">Select Client</option>
                {clients.map((client) => (
                  <option key={client.client_id} value={client.client_id}>
                    {client.client}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="caseID" className="block text-gray-700 text-sm font-bold mb-2">
                Choose Case
              </label>
              <select
                id="caseID"
                name="caseID"
                className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={handleCaseSelectChange}
                value={selectedCase}
                disabled={!selectedClient}
              >
                <option value="">Select Case</option>
                {cases
                  .filter((ca) => ca.client_id === selectedClient)
                  .map((ca) => (
                    <option key={ca._id} value={ca._id}>
                      {ca.caseLabel}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                cols="50"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter message"
                onChange={handleChange}
                value={formData.message}
              ></textarea>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
            {message && (
              <div className="text-center mt-4">
                <p className="text-green-500">{message}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMessage;
